const FormHelper = (instance, key) => {
    if (typeof key !== 'undefined') {
        return {
            onChange: (e) => {
                if (e.target.type === 'checkbox') {
                    instance.setState({
                        ...instance.state,
                        [key]: {
                            ...instance.state[key],
                            [e.target.name]: !instance.state[e.target.name]
                        }
                    })
                }
                else {
                    instance.setState({
                        ...instance.state,
                        [key]: {
                            ...instance.state[key],
                            [e.target.name]: e.target.value
                        }
                    })
                }
            }
        }
    }
    return {
        onChange: (e) => {
            if (e.target.type === 'checkbox') {
                instance.setState({
                    ...instance.state,
                    rememberMe: !instance.state.rememberMe
                })
            }
            else {
                instance.setState({
                    ...instance.state,
                    [e.target.name]: e.target.value
                })
            }
        }
    }
}

export default FormHelper