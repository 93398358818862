import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import iconNew from '../../../assets/icons/new-light.svg'
import Pagination from '../../../components/pagination/pagination.component'
import './programs.styles.scss'
import Service from './programs.service'
import NumberFormat from 'react-number-format'

class Programs extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadPrograms()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadPrograms())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadPrograms()
    }

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                <div className="programs">
                    <div className="header">
                        <h4>Programs</h4>
                        <div className="buttons">
                            <Link className="btn" to="/training/programs/create"><img alt="" src={iconNew} />New Program</Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Fee</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.programs.map((program, key) => <tr key={key}>
                                    <td>{program.code}</td>
                                    <td>{program.title}</td>
                                    <td>{program.description}</td>
                                    <td><NumberFormat value={program.fee} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Programs))