import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Service from './edit.service'
import Form from './form.component'
import FormHelper from '../../../../../../utils/FormHelper'
import './edit.styles.scss'
import Dialog from '../../../../../../components/dialog/dialog.component'
import Loading from '../../../../../../components/loading/loading.component'

class Edit extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadStudent()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.setState({
            ...this.state,
            saving: true
        }, this.service.save)
    }

    onPhotoChange = (e) => {
        if (e.target.files.length === 0) {
            return
        }
        let data = new FormData()
        data.append('photo', e.target.files[0])
        data.append("studentId", this.state.student.id);
        this.setState({
            ...this.state,
            uploading: true
        }, () => this.service.uploadPhoto(data))
    }

    render() {
        return (
            <div className="edit card">
                {(this.state.saving || this.state.uploading || this.state.loadingStudent) && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Edit Student</h4>
                </div>
                <div className={"flag " + this.state.flag.type}>
                    {this.state.flag.text}
                </div>
                {
                    this.state.student !== null && <Form {...this.state} onChange={FormHelper(this, 'student').onChange} onPhotoChange={this.onPhotoChange} onSubmit={this.onSubmit} onCancel={this.props.onCancel} />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Edit))