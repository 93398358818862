import React from 'react'
import { Switch, Route } from "react-router-dom"
import Students from './students.page'
import Create from './create/create.page'
import Details from './details/details.page'

const StudentController = () => {
    return (
        <div className="student">
            <Switch>
                <Route path="/training/students/create" component={Create} />
                <Route path="/training/students/all" component={Students} />
                <Route path="/training/students/:id" component={Details} />
            </Switch>
        </div>
    )
}

export default StudentController