import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import iconNew from '../../../assets/icons/new-light.svg'
import Pagination from '../../../components/pagination/pagination.component'
import './sessions.styles.scss'
import Service from './sessions.service'
import { FormatDate } from '../../../utils/UIHelper'

class Sessions extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadSessions()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadSessions())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadSessions()
    }

    getStatusLabel = status => {
        switch(status){
            case 1:
                return "Registration Ongoing"
            case 2:
                return "Registration Closed"
            case 3:
                return "Ended"
            default:
                return "Unknown"
        }
    }

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                <div className="sessions">
                    <div className="header">
                        <h4>Sessions</h4>
                        <div className="buttons">
                            <Link className="btn" to="/training/sessions/create"><img alt="" src={iconNew} />New Session</Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Program</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.sessions.map((session, key) => <tr key={key}>
                                    <td>{session.code}</td>
                                    <td>{session.program.title}</td>
                                    <td>{FormatDate(session.start*1000)}</td>
                                    <td>{FormatDate(session.end*1000)}</td>
                                    <td>{this.getStatusLabel(session.status)}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Sessions))