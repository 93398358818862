import React from 'react'
import { Switch, Route } from "react-router-dom"
import Users from './users.page'
import UserCreate from './create/create.page'

const UserController = () => {
    return (
        <Switch>
            <Route path="/security/users/create" component={UserCreate} />
            <Route exact path="/security/users/all" component={Users} />
        </Switch>
    )
}

export default UserController