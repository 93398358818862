const hasPermission = (auth, permissions) => {
    permissions.push(1)
    for (let i = 0; i < auth.rolePermissions.length; i++) {
        if (permissions.includes(auth.rolePermissions[i].permissionId)) {
            return true
        }
    }
    return false
}

const AuthHelper = auth => {
    return {
        hasPermission: permissions => hasPermission(auth, permissions)
    }
}

export default AuthHelper