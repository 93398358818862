import axios from 'axios'
import Validation from '../../../../data/validation/validation'
import { PhoneValidationRule, EmailValidationRule, NumberValidationRule, StringValidationRule } from '../../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../../config'
import { LoadSessions } from '../common.service'

const getDefaultState = () => {
    return {
        saving: false,
        firstname: "",
        lastname: "",
        gender: "",
        age: "",
        email: "",
        school: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "Delta",
        country: "",
        guardianFirstname: "",
        guardianLastname: "",
        guardianPhone: "",
        guardianEmail: "",
        guardianRelationship: "",
        sessionId: "",
        sessions: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => errors.lastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.gender, (error) => errors.gender = error, { min: { value: 1, error: "Too short" }, max: { value: 1, error: "Too long" } })
    validation.addValidationRule(NumberValidationRule, instance.state.age, (error) => errors.age = error, { min: { value: 5, error: "Too low" }, max: { value: 40, error: "Too High" } })
    validation.addValidationRule(StringValidationRule, instance.state.school, (error) => errors.school = error, { min: { value: 0, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.addressLine1, (error) => instance.state.errors.addressLine1 = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.addressLine2, (error) => instance.state.errors.addressLine2 = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.city, (error) => instance.state.errors.city = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.state, (error) => instance.state.errors.state = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.guardianFirstname, (error) => errors.guardianFirstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.guardianLastname, (error) => errors.guardianLastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.guardianPhone, (error) => errors.guardianPhone = error, { allowNull: false })
    validation.addValidationRule(EmailValidationRule, instance.state.guardianEmail, (error) => errors.guardianEmail = error, { allowNull: true })
    validation.addValidationRule(StringValidationRule, instance.state.guardianRelationship, (error) => errors.guardianRelationship = error, { min: { value: 2, error: "Too short" }, max: { value: 15, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.sessionId, (error) => errors.sessionId = error, { min: { value: 20, error: "Too short" }, max: { value: 45, error: "Too long" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const save = (instance) => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/student/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            firstname: instance.state.firstname,
            lastname: instance.state.lastname,
            gender: instance.state.gender,
            age: Number(instance.state.age),
            school: instance.state.school,
            email: instance.state.email,
            addressLine1: instance.state.addressLine1,
            addressLine2: instance.state.addressLine2,
            city: instance.state.city,
            state: instance.state.state,
            country: instance.state.country,
            guardianFirstname: instance.state.guardianFirstname,
            guardianLastname: instance.state.guardianLastname,
            guardianPhone: instance.state.guardianPhone,
            guardianEmail: instance.state.guardianEmail,
            guardianRelationship: instance.state.guardianRelationship,
            sessionId: instance.state.sessionId
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                firstname: "",
                lastname: "",
                gender: "",
                age: "",
                email: "",
                school: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "Delta",
                country: "",
                guardianFirstname: "",
                guardianLastname: "",
                guardianPhone: "",
                guardianEmail: "",
                guardianRelationship: "",
                sessionId: "",
                saving: false,
                flag: {
                    type: "success",
                    text: "Created successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadSessions: () => LoadSessions(instance),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service