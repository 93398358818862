import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = () => {
    return {
        loadingStudent: true,
        showAddPlan: false,
        showChangeSession: false,
        showMakeActive: false,
        showEdit: false,
        showDisconnect: false,
        student: null,
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadStudent = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/student",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.match.params.id
        }
    }).then(response => handleLoadStudentResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadStudentResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingStudent: false,
                student: response.data.data.student
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadStudent: () => loadStudent(instance)
    }
}

export default Service