import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import iconNew from '../../../assets/icons/new-light.svg'
import Pagination from '../../../components/pagination/pagination.component'
import './students.styles.scss'
import Service from './students.service'
import Filters from './filters.component'
import AddAssessment from './details/components/add-assessment/add-assessment.component'
import iconWhatsApp from '../../../assets/icons/whatsapp.svg'

class Students extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadStudents()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadStudents())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadStudents()
    }

    toggleShowAddAssessment = index => {
        this.setState({
            ...this.state,
            selectedIndex: index,
            showAddAssessment: !this.state.showAddAssessment
        })
    }

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                {this.state.showAddAssessment && <Dialog><AddAssessment name={this.state.selectedIndex !== null ? this.state.students[this.state.selectedIndex].student.firstname : ""} studentSessionId={this.state.students[this.state.selectedIndex].id} onCancel={() => this.toggleShowAddAssessment(null)} onAssessmentAdded={() => this.toggleShowAddAssessment(null)} /></Dialog>}
                <div className="students">
                    <div className="header">
                        <h4>Students</h4>
                        <div className="buttons">
                            <Link className="btn" to="/training/students/create"><img alt="" src={iconNew} />New Registration</Link>
                            <Filters filter={this.state.filter} onSubmit={this.onFilterSubmit} onChange={this.onFilterChange} />
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Registration Number</th>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Age</th>
                                <th>Guardian</th>
                                <th>Phone</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.students.map((student, key) => <tr key={key}>
                                    <td>{student.regNumber}</td>
                                    <td>{student.firstname} {student.lastname}</td>
                                    <td>{student.gender}</td>
                                    <td>{student.age}</td>
                                    <td>{student.guardianFirstname} {student.guardianLastname}</td>
                                    <td>{student.guardianPhone}</td>
                                    <td>
                                        <div className="actions">
                                            <button onClick={() => this.props.history.push('/training/students/' + student.id)}>More</button>
                                            <a rel="noreferrer" target="_blank" href={"https://wa.me/234"+ student.guardianPhone.substring(1) +"/?text="+encodeURI("https://skysenx.com/techcamp/assessment?regNumber="+ student.regNumber)}>
                                                <img alt="" src={iconWhatsApp}/>
                                            </a>
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Students))