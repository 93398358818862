import React from 'react'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="assignment">
                    <div className="form-label">
                        <label>Assignment</label>
                    </div>
                    <div className="form-input">
                        <select name="assignment" onChange={props.onChange} value={props.assignment}>
                            <option value=""></option>
                            <option value="5">None</option>
                            <option value="6">No Attempt</option>
                            <option value="2">Good</option>
                            <option value="3">Very Good</option>
                            <option value="4">Excellent</option>
                        </select>
                        <span className="error">{props.errors.assignment}</span>
                    </div>
                </div>
                <div className="row" id="behaviour">
                    <div className="form-label">
                        <label>Behaviour</label>
                    </div>
                    <div className="form-input">
                        <select name="behaviour" onChange={props.onChange} value={props.behaviour}>
                            <option value=""></option>
                            <option value="0">Poor</option>
                            <option value="1">Fair</option>
                            <option value="2">Good</option>
                            <option value="3">Very Good</option>
                            <option value="4">Excellent</option>
                        </select>
                        <span className="error">{props.errors.behaviour}</span>
                    </div>
                </div>
                <div className="row" id="attentive">
                    <div className="form-label">
                        <label>Attentive</label>
                    </div>
                    <div className="form-input">
                        <select name="attentive" onChange={props.onChange} value={props.attentive}>
                            <option value=""></option>
                            <option value="0">Poor</option>
                            <option value="1">Fair</option>
                            <option value="2">Good</option>
                            <option value="3">Very Good</option>
                            <option value="4">Excellent</option>
                        </select>
                        <span className="error">{props.errors.attentive}</span>
                    </div>
                </div>
                <div className="row" id="date">
                    <div className="form-label">
                        <label>Date</label>
                    </div>
                    <div className="form-input">
                        <input type="date" name="date" value={props.date} onChange={props.onChange} />
                        <span className="error">{props.errors.date}</span>
                    </div>
                </div>
                <div className="row" id="remarks">
                    <div className="form-label">
                        <label>Remarks</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="remarks" value={props.remarks} onChange={props.onChange} />
                        <span className="error">{props.errors.remarks}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={props.onCancel}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default Form