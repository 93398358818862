import React from 'react'
import './info.styles.scss'
import { FormatDate } from '../../../../../../utils/UIHelper'
import Service from './info.service'
import Dialog from '../../../../../../components/dialog/dialog.component'
import AddAssessment from '../add-assessment/add-assessment.component'
import ChangeSession from '../change-session/change-session.component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EditComponent from '../edit/edit.component'
import imageAvatar from '../../../../../../assets/images/avatar.png'

class Info extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    toggleShowChangeSession = () => this.setState({
        ...this.state,
        showChangeSession: !this.state.showChangeSession
    })

    toggleShowAddAssessment = () => this.setState({
        ...this.state,
        showAddAssessment: !this.state.showAddAssessment
    })

    toggleShowEdit = () => this.setState({
        ...this.state,
        showEdit: !this.state.showEdit
    })

    onAssessmentAdded = () => {
        this.toggleShowAddAssessment()
        this.props.onReload()
    }

    onSessionChanged = () => {
        this.toggleShowChangeSession()
        this.props.onReload()
    }

    onInfoChanged = () => {
        this.toggleShowEdit()
        this.props.onReload()
    }

    getRatingLabel = rating => {
        switch (rating) {
            case 0:
                return "Poor"
            case 1:
                return "Fair"
            case 2:
                return "Good"
            case 3:
                return "Very Good"
            case 4:
                return "Excellent"
            case 5:
                return "None"
            case 6:
                return "No Attempt"
            default:
                return "Unknown"
        }
    }

    render() {
        return (
            <div className="info">
                {this.state.showChangeSession && <Dialog><ChangeSession studentId={this.props.id} onCancel={this.toggleShowChangeSession} onSessionChanged={this.onSessionChanged} /></Dialog>}
                {this.state.showAddAssessment && <Dialog><AddAssessment studentId={this.props.id} onCancel={this.toggleShowAddAssessment} onAssessmentAdded={this.onAssessmentAdded} /></Dialog>}
                {this.state.showEdit && <Dialog><EditComponent studentId={this.props.id} onCancel={this.toggleShowEdit} onInfoChanged={this.onInfoChanged} /></Dialog>}
                <div className="boxes">
                    <div className="box">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Reg. Number</td>
                                    <td>{this.props.regNumber}</td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td>{this.props.firstname} {this.props.lastname}</td>
                                </tr>
                                <tr>
                                    <td>Gender</td>
                                    <td>{this.props.gender === 'M' ? "Male" : "Female"}</td>
                                </tr>
                                <tr>
                                    <td>Age</td>
                                    <td>{this.props.age}</td>
                                </tr>
                                <tr>
                                    <td>Current School</td>
                                    <td>{this.props.school}</td>
                                </tr>
                                <tr>
                                    <td>Guardian</td>
                                    <td>{this.props.guardianFirstname} {this.props.guardianLastname}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>{this.props.guardianPhone}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{this.props.guardianEmail}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        {this.props.addressLine1}
                                        <br />{this.props.addressLine2}
                                        <br />{this.props.city}, {this.props.state}, Nigeria
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="header">
                            <div className="buttons">
                                <button className="btn" onClick={this.toggleShowAddAssessment}>Add Report</button>
                                <button className="btn" onClick={this.toggleShowChangeSession}>Change Program</button>
                                <button className="btn" onClick={this.toggleShowEdit}>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="photo">
                            <img src={this.props.photos.length > 0 ? "https://s3.amazonaws.com/cdn.skysenx.com/" + this.props.photos[0] : imageAvatar} alt="" />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="header">
                        <h4>Assessments</h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Assignment</th>
                                <th>Behaviour</th>
                                <th>Attentive</th>
                                <th>Remarks</th>
                                <th>Instructor</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.assessments.map((assessment, key) => <tr key={key}>
                                    <td>{this.getRatingLabel(assessment.assignment)}</td>
                                    <td>{this.getRatingLabel(assessment.behaviour)}</td>
                                    <td>{this.getRatingLabel(assessment.attentive)}</td>
                                    <td>{assessment.remarks}</td>
                                    <td>{assessment.instructor.firstname} {assessment.instructor.lastname}</td>
                                    <td>{FormatDate(assessment.date * 1000)}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Info))