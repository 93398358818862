import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Service from './details.service'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import Info from './components/info/info.component'

class Detail extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadStudent()
    }

    toggleShowChangeSession = () => this.setState({
        ...this.state,
        showChangeSession: !this.state.showChangeSession
    })

    toggleShowMakeActive = () => this.setState({
        ...this.state,
        showMakeActive: !this.state.showMakeActive
    })

    toggleShowEdit = () => {
        this.setState({
            ...this.state,
            showEdit: !this.state.showEdit
        })
    }

    toggleShowDisconnect = () => {
        this.setState({
            ...this.state,
            showDisconnect: !this.state.showDisconnect
        })
    }

    onStudentSessionReload = student => {
        this.setState({
            ...this.state,
            student: student
        })
    }

    onReload = () => {
        this.setState({
            ...this.state,
            loadingStudent: true
        }, this.service.loadStudent)
    }

    reloadPage = () => window.location.reload();

    render() {
        return (
            <div className="details">
                {this.state.loadingStudent && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Student Details</h4>
                </div>
                <div className="card">
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    {
                        this.state.student !== null && <Info key={this.state.student.id} {...this.state.student} onReload={this.onReload}/>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Detail))