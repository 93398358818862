import React from 'react'
import { Switch, Route } from "react-router-dom"
import SessionController from './sessions/session.controller'
import ProgramController from './programs/program.controller'
import StudentController from './students/student.controller'
import './common.styles.scss'

const TrainingController = () => {
    return (
        <div className="training">
            <Switch>
                <Route path="/training/students" component={StudentController} />
                <Route path="/training/sessions" component={SessionController} />
                <Route path="/training/programs" component={ProgramController} />
            </Switch>
        </div>
    )
}

export default TrainingController