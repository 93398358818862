import React from 'react'
import { Switch, Route } from "react-router-dom"
import AuthLogin from './login/login.page'
import './common.styles.scss'

const AuthController = () => {
    return (
        <Switch>
            <Route path="/auth" component={AuthLogin} />
        </Switch>
    )
}

export default AuthController