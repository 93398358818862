
const getDefaultState = () => {
    return {
        showAddAssessment: false,
        showChangeSession: false,
        showEdit: false
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service