import React from 'react'
import { withRouter } from 'react-router-dom'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="sessionId">
                    <div className="form-label">
                        <label>Session</label>
                    </div>
                    <div className="form-input">
                        <select name="sessionId" onChange={props.onChange} value={props.sessionId}>
                            <option value=""></option>
                            {
                                props.sessions.map((session, key) => <option key={key} value={session.id}>{session.code}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.sessionId}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={props.onCancel}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)