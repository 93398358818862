import React from 'react'
import { withRouter } from 'react-router-dom'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="code">
                    <div className="form-label">
                        <label>Code</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="code" value={props.code} onChange={props.onChange} />
                        <span className="error">{props.errors.code}</span>
                    </div>
                </div>
                <div className="row" id="title">
                    <div className="form-label">
                        <label>Program</label>
                    </div>
                    <div className="form-input">
                        <select name="program" onChange={props.onChange} defaultValue={props.program}>
                            <option value=""></option>
                            {
                                props.programs.map((program, key) => <option key={key} value={program.id}>{program.title}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.program}</span>
                    </div>
                </div>
                <div className="row" id="start">
                    <div className="form-label">
                        <label>Start Date</label>
                    </div>
                    <div className="form-input">
                        <input type="date" name="start" value={props.start} onChange={props.onChange} />
                        <span className="error">{props.errors.start}</span>
                    </div>
                </div>
                <div className="row" id="end">
                    <div className="form-label">
                        <label>End Date</label>
                    </div>
                    <div className="form-input">
                        <input type="date" name="end" value={props.end} onChange={props.onChange} />
                        <span className="error">{props.errors.end}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/training/sessions/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)