import React from 'react'
import { withRouter } from 'react-router-dom'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="code">
                    <div className="form-label">
                        <label>Code</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="code" value={props.code} onChange={props.onChange} />
                        <span className="error">{props.errors.code}</span>
                    </div>
                </div>
                <div className="row" id="title">
                    <div className="form-label">
                        <label>Title</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="title" value={props.title} onChange={props.onChange} />
                        <span className="error">{props.errors.title}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row" id="fee">
                    <div className="form-label">
                        <label>Fee</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="fee" value={props.fee} onChange={props.onChange} />
                        <span className="error">{props.errors.fee}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/training/programs/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)