import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'
import Validation from '../../../../../../data/validation/validation'
import { PhoneValidationRule, EmailValidationRule, NumberValidationRule, StringValidationRule } from '../../../../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../../../../utils/UIHelper'

const getDefaultState = instance => {
    return {
        saving: false,
        loadingStudent: true,
        uploading: false,
        student: null,
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.student.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.student.lastname, (error) => errors.lastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.student.gender, (error) => errors.gender = error, { min: { value: 1, error: "Too short" }, max: { value: 1, error: "Too long" } })
    validation.addValidationRule(NumberValidationRule, instance.state.student.age, (error) => errors.age = error, { min: { value: 5, error: "Too low" }, max: { value: 40, error: "Too High" } })
    validation.addValidationRule(StringValidationRule, instance.state.student.school, (error) => errors.school = error, { min: { value: 0, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.student.addressLine1, (error) => instance.state.errors.addressLine1 = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.student.addressLine2, (error) => instance.state.errors.addressLine2 = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.student.city, (error) => instance.state.errors.city = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.student.state, (error) => instance.state.errors.state = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.student.guardianFirstname, (error) => errors.guardianFirstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.student.guardianLastname, (error) => errors.guardianLastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.student.guardianPhone, (error) => errors.guardianPhone = error, { allowNull: false })
    validation.addValidationRule(EmailValidationRule, instance.state.student.guardianEmail, (error) => errors.guardianEmail = error, { allowNull: true })
    validation.addValidationRule(StringValidationRule, instance.state.student.guardianRelationship, (error) => errors.guardianRelationship = error, { min: { value: 2, error: "Too short" }, max: { value: 15, error: "Too long" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}


const loadStudent = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/student",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.studentId
        }
    }).then(response => handleStudentResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingStudent: false
            })
            alert(error)
        })
}

const handleStudentResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingStudent: false,
                student: response.data.data.student
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingStudent: false
            })
            alert(response.data.message)
    }
}

const save = (instance) => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/student/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            ...instance.state.student,
            id: instance.props.studentId,
            age: Number(instance.state.student.age),
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "",
                    text: ""
                }
            })
            instance.props.onInfoChanged()
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const uploadPhoto = (instance, data) => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/student/photos/upload",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: data
    }).then(response => handleUploadPhotoResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                uploading: false
            })
            alert(error)
        })
}

const handleUploadPhotoResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                uploading: false,
                student: {
                    ...instance.state.student,
                    photos: [
                        response.data.data.photo
                    ]
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                uploading: false
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadStudent: () => loadStudent(instance),
        isValid: () => isValid(instance),
        uploadPhoto: data => uploadPhoto(instance, data),
        save: () => save(instance)
    }
}

export default Service