import axios from 'axios'
import Validation from '../../../../data/validation/validation'
import { DateValidationRule, StringValidationRule } from '../../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = () => {
    return {
        loading: false,
        saving: false,
        code: "",
        program: "",
        start: "",
        end: "",
        programs: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadPrograms = instance => {
    instance.setState({ ...instance.state, loading: true })
    let criteria = {}
    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/programs",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria
        }
    }).then(response => handleLoadProgramsResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadProgramsResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                programs: response.data.data.programs
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.code, (error) => instance.state.errors.code = error, { min: { value: 2, error: "Invalid" }, max: { value: 6, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.program, (error) => instance.state.errors.program = error, { min: { value: 25, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    validation.addValidationRule(DateValidationRule, instance.state.start, (error) => instance.state.errors.start = error, { allowNull: false} )
    validation.addValidationRule(DateValidationRule, instance.state.end, (error) => instance.state.errors.end = error, { allowNull: false} )

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/sessions/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            code: instance.state.code,
            programId: instance.state.program,
            start: (new Date(instance.state.start)).getTime()/1000,
            end: (new Date(instance.state.end)).getTime()/1000
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                code: "",
                start: "",
                end: "",
                program: "",
                saving: false,
                flag: {
                    type: "success",
                    text: "Created successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadPrograms: () => loadPrograms(instance),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service