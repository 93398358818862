import React from 'react'
import { withRouter } from 'react-router-dom'
import imageAvatar from '../../../../../../assets/images/avatar.png'
import States from '../../../../../../assets/data/states'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="col">
                    <div className="row" id="firstname">
                        <div className="form-label">
                            <label>Firstname</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="firstname" value={props.student.firstname} onChange={props.onChange} />
                            <span className="error">{props.errors.firstname}</span>
                        </div>
                    </div>
                    <div className="row" id="lastname">
                        <div className="form-label">
                            <label>Lastname</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="lastname" value={props.student.lastname} onChange={props.onChange} />
                            <span className="error">{props.errors.lastname}</span>
                        </div>
                    </div>
                    <div className="row" id="gender">
                        <div className="form-label">
                            <label>Gender</label>
                        </div>
                        <div className="form-input">
                            <select name="gender" value={props.student.gender} onChange={props.onChange}>
                                <option value=""></option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                            <span className="error">{props.errors.gender}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-label">
                            <label>Age</label>
                        </div>
                        <div className="form-input">
                            <input type="number" name="age" value={props.student.age} onChange={props.onChange} />
                            <span className="error">{props.errors.age}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-label">
                            <label>Current School</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="school" value={props.student.school} onChange={props.onChange} />
                            <span className="error">{props.errors.school}</span>
                        </div>
                    </div>
                    <div className="form-input" id="addressLine1">
                        <label>Address</label>
                        <input type="text" name="addressLine1" placeholder="Street name" value={props.student.addressLine1} onChange={props.onChange} />
                        <span className="error">{props.errors.addressLine1}</span>
                    </div>
                    <div className="form-input" id="addressLine2">
                        <label></label>
                        <input type="text" name="addressLine2" placeholder="Area" value={props.student.addressLine2} onChange={props.onChange} />
                        <span className="error">{props.errors.addressLine2}</span>
                    </div>
                    <div className="form-input" id="city">
                        <label>City</label>
                        <input type="text" name="city" value={props.student.city} onChange={props.onChange} />
                        <span className="error">{props.errors.city}</span>
                    </div>
                    <div className="form-input" id="state">
                        <label>State</label>
                        <select name="state" value={props.student.state} onChange={props.onChange}>
                            <option value=""></option>
                            {
                                States.map((state, key) => <option key={key} value={state}>{state}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.state}</span>
                    </div>
                    <div className="form-input">
                        <label>Country</label>
                        <input type="text" name="country" disabled value={"Nigeria"} />
                    </div>
                </div>
                <div className="col">
                    <div className="photo">
                        <img src={props.student.photos.length > 0 ? "https://s3.amazonaws.com/cdn.skysenx.com/" + props.student.photos[0] : imageAvatar} alt="" />
                        <div className='upload-btn-wrapper'>
                            <span className="btn-upload">Upload Photo</span>
                            <input type="file" name="photo" accept=".jpg" onChange={props.onPhotoChange} />
                        </div>
                    </div>
                    <div className="header">
                        <h4>Guardian Info</h4>
                    </div>
                    <div className="row" id="guardianFirstname">
                        <div className="form-label">
                            <label>Firstname</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="guardianFirstname" value={props.student.guardianFirstname} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianFirstname}</span>
                        </div>
                    </div>
                    <div className="row" id="guardianLastname">
                        <div className="form-label">
                            <label>Lastname</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="guardianLastname" value={props.student.guardianLastname} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianLastname}</span>
                        </div>
                    </div>
                    <div className="row" id="guardianPhone">
                        <div className="form-label">
                            <label>Phone</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="guardianPhone" value={props.student.guardianPhone} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianPhone}</span>
                        </div>
                    </div>
                    <div className="row" id="guardianEmail">
                        <div className="form-label">
                            <label>Email</label>
                        </div>
                        <div className="form-input">
                            <input type="email" name="guardianEmail" value={props.student.guardianEmail} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianEmail}</span>
                        </div>
                    </div>
                    <div className="row" id="guardianRelationship">
                        <div className="form-label">
                            <label>Relationship</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="guardianRelationship" value={props.student.guardianRelationship} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianRelationship}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={props.onCancel}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)