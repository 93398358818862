import axios from 'axios'
import { API_ENDPOINT } from '../../../config'


export const LoadSessions = instance => {
    instance.setState({ ...instance.state, loading: true })
    let criteria = {}
    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/sessions",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 7,
            criteria: criteria
        }
    }).then(response => handleLoadSessionsResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadSessionsResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                sessions: response.data.data.sessions
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}