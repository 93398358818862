import React from 'react'
import { Switch, Route } from "react-router-dom"
import Sessions from './sessions.page'
import Create from './create/create.page'

const SessionController = () => {
    return (
        <div className="session">
            <Switch>
                <Route path="/training/sessions/create" component={Create} />
                <Route path="/training/sessions/all" component={Sessions} />
            </Switch>
        </div>
    )
}

export default SessionController