import React from 'react'
import { Switch, Route } from "react-router-dom"
import Programs from './programs.page'
import Create from './create/create.page'

const ProgramController = () => {
    return (
        <div className="program">
            <Switch>
                <Route path="/training/programs/create" component={Create} />
                <Route path="/training/programs/all" component={Programs} />
            </Switch>
        </div>
    )
}

export default ProgramController