import Validation from '../../../../../../data/validation/validation'
import { StringValidationRule, DateValidationRule } from '../../../../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../../../../utils/UIHelper'
import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'

const getDefaultState = () => {
    return {
        saving: false,
        assignment: "",
        behaviour: "",
        attentive: "",
        date: "",
        remarks: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.assignment, (error) => instance.state.errors.assignment = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.behaviour, (error) => instance.state.errors.behaviour = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.attentive, (error) => instance.state.errors.attentive = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(DateValidationRule, instance.state.date, (error) => instance.state.errors.date = error, { allowNull: false })
    validation.addValidationRule(StringValidationRule, instance.state.remarks, (error) => instance.state.errors.remarks = error, { min: { value: 0, error: "Invalid" }, max: { value: 100, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const save = (instance) => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/student/assessment/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            studentId: instance.props.studentId,
            assignment: instance.state.assignment,
            behaviour: instance.state.behaviour,
            attentive: instance.state.attentive,
            date: (new Date(instance.state.date)).getTime()/1000,
            remarks: instance.state.remarks
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.props.onAssessmentAdded()
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service