import React from 'react'
import { withRouter } from 'react-router-dom'
import './form.styles.scss'
import States from '../../../../../assets/data/states'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="col">
                    <div className="header">
                        <h4>Student Info</h4>
                    </div>
                    <div className="row" id="firstname">
                        <div className="form-label">
                            <label>Firstname</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="firstname" value={props.firstname} onChange={props.onChange} />
                            <span className="error">{props.errors.firstname}</span>
                        </div>
                    </div>
                    <div className="row" id="lastname">
                        <div className="form-label">
                            <label>Lastname</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="lastname" value={props.lastname} onChange={props.onChange} />
                            <span className="error">{props.errors.lastname}</span>
                        </div>
                    </div>
                    <div className="row" id="gender">
                        <div className="form-label">
                            <label>Gender</label>
                        </div>
                        <div className="form-input">
                            <select name="gender" value={props.gender} onChange={props.onChange}>
                                <option value=""></option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                            <span className="error">{props.errors.gender}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-label">
                            <label>Age</label>
                        </div>
                        <div className="form-input">
                            <input type="number" name="age" value={props.age} onChange={props.onChange} />
                            <span className="error">{props.errors.age}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-label">
                            <label>Current School</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="school" value={props.school} onChange={props.onChange} />
                            <span className="error">{props.errors.school}</span>
                        </div>
                    </div>
                    <div className="form-input" id="addressLine1">
                        <label>Address</label>
                        <input type="text" name="addressLine1" placeholder="Street name" value={props.addressLine1} onChange={props.onChange} />
                        <span className="error">{props.errors.addressLine1}</span>
                    </div>
                    <div className="form-input" id="addressLine2">
                        <label></label>
                        <input type="text" name="addressLine2" placeholder="Area" value={props.addressLine2} onChange={props.onChange} />
                        <span className="error">{props.errors.addressLine2}</span>
                    </div>
                    <div className="form-input" id="city">
                        <label>City</label>
                        <input type="text" name="city" value={props.city} onChange={props.onChange} />
                        <span className="error">{props.errors.city}</span>
                    </div>
                    <div className="form-input" id="state">
                        <label>State</label>
                        <select name="state" value={props.state} onChange={props.onChange}>
                            <option value=""></option>
                            {
                                States.map((state, key) => <option key={key} value={state}>{state}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.state}</span>
                    </div>
                    <div className="form-input">
                        <label>Country</label>
                        <input type="text" name="country" disabled value={"Nigeria"} />
                    </div>
                </div>
                <div className="col">
                    <div className="header">
                        <h4>Guardian Info</h4>
                    </div>
                    <div className="row" id="guardianFirstname">
                        <div className="form-label">
                            <label>Firstname</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="guardianFirstname" value={props.guardianFirstname} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianFirstname}</span>
                        </div>
                    </div>
                    <div className="row" id="guardianLastname">
                        <div className="form-label">
                            <label>Lastname</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="guardianLastname" value={props.guardianLastname} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianLastname}</span>
                        </div>
                    </div>
                    <div className="row" id="guardianPhone">
                        <div className="form-label">
                            <label>Phone</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="guardianPhone" value={props.guardianPhone} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianPhone}</span>
                        </div>
                    </div>
                    <div className="row" id="guardianEmail">
                        <div className="form-label">
                            <label>Email</label>
                        </div>
                        <div className="form-input">
                            <input type="email" name="guardianEmail" value={props.guardianEmail} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianEmail}</span>
                        </div>
                    </div>
                    <div className="row" id="guardianRelationship">
                        <div className="form-label">
                            <label>Relationship</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="guardianRelationship" value={props.guardianRelationship} onChange={props.onChange} />
                            <span className="error">{props.errors.guardianRelationship}</span>
                        </div>
                    </div>
                    <div className="header">
                        <h4>Program Info</h4>
                    </div>
                    <div className="row" id="sessionId">
                        <div className="form-label">
                            <label>Session</label>
                        </div>
                        <div className="form-input">
                            <select name="sessionId" onChange={props.onChange} defaultValue={props.sessionId}>
                                <option value=""></option>
                                {
                                    props.sessions.map((session, key) => <option key={key} value={session.id}>{session.code}</option>)
                                }
                            </select>
                            <span className="error">{props.errors.sessionId}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/training/students/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)